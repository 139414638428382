import React from "react";
import axios from "axios";
import {
  Paper,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
import { Delete } from "@material-ui/icons";
import "../_post-details.scss";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import DeleteModal from "../../modals/DeleteModal";
import EditIcon from "@material-ui/icons/Edit";

const Connect = ({
  email,
  phone,
  openEditModal,
  memberID,
  fetchAd,
  makePhonePublic,
  adId,
}) => {
  const Show = ({ icon, message, openEditModal, type }) => {
    return (
      <table className="respond-ways">
        <tbody>
          <tr>
            <td>
              <i className={icon}></i>
            </td>
            <td>
              <span
                style={
                  isAdminOrOperatorL2()
                    ? { fontWeight: 500, cursor: "pointer" }
                    : { fontWeight: 500 }
                }
                onClick={
                  isAdminOrOperatorL2()
                    ? () => {
                        openEditModal(
                          type,
                          type === "Phone" ? "phone" : "email",
                          "text",
                          message,
                          null,
                          null,
                          "SELF"
                        );
                      }
                    : null
                }
              >
                {message ? (
                  message
                ) : (
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ paddingRight: 10 }}>
                      <Typography>N/A </Typography>
                    </div>
                    <EditIcon fontSize="small" style={{ color: "#7b7b7b" }} />
                  </div>
                )}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const deleteUserEmail = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/user/${memberID}/remove_email`,
        undefined,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Successfully Email Deleted", "Success");
        fetchAd();
      })
      .catch((err) => {
        NotificationManager.error(" An error occured", "Error");
        console.log(err);
      });
  };

  const phoneNumberVisibilityCheck = (e) => {
    const data = {
      makePhonePublic: e.target.checked,
    };

    try {
      axios
        .put(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${adId}`, data, {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          NotificationManager.success("Post updated", "Success");
          fetchAd();
        });
    } catch (err) {
      NotificationManager.error(" An error occured", "Error");
      console.log(err);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          className="complete-ad-div settings-container post-details-con"
        >
          <Typography
            variant="h6"
            className="heading"
            style={{ marginBottom: 20 }}
          >
            Respond
          </Typography>
          <Grid container direction="row" style={{ columnGap: 65 }}>
            <Show
              icon="fas fa-phone fa-rotate-90"
              message={phone}
              openEditModal={openEditModal}
              type={"Phone"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedI"
                  value={makePhonePublic}
                  onChange={phoneNumberVisibilityCheck}
                  checked={makePhonePublic}
                  color={"default"}
                />
              }
              label={
                <Typography style={{ fontSize: 15 }}>
                  Share phone number with public
                </Typography>
              }
            ></FormControlLabel>
          </Grid>

          <div className="email-main-container">
            <Show
              icon="fas fa-at"
              message={email}
              openEditModal={openEditModal}
              type={"Email"}
            />
            {email ? (
              <div className="delete-icon-container">
                <Delete
                  className="delete-icon"
                  fontSize="small"
                  data-toggle="modal"
                  data-target="#deleteModal"
                />
              </div>
            ) : null}
          </div>
        </Paper>
      </Grid>
      <DeleteModal
        title={"Are You sure ?"}
        description={"Do you want to Delete the email ? "}
        handleDelete={deleteUserEmail}
      />
    </>
  );
};

Connect.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  openEditModal: PropTypes.func.isRequired,
};

export default Connect;
